// backgrund container that should cover the entire page
.container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
}

.imagesContainer {
    display: flex;
    flex-direction: row;
    animation: slideInRight 60s infinite alternate; // Apply the animation to the row class
}

.image {
    height: 100vh;
    aspect-ratio: 0.5625;
    margin-right: -5vh;
    margin-left: -5vh;
    object-fit: cover;
    object-position: center;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

@keyframes slideInRight {
    0% {
        transform: translateX(0vh); // Start with rotation and translation
    }

    100% {
        transform: translateX(-300vh); // End with rotation and translation
    }
}