@import './../../consts/LAYOUT.scss';

.container {
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 2;
}

.navbarBackground {
    height: 100px;
    width: 100vw;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shadow {
    height: 60px;
    margin-bottom: -60px;
    width: 100vw;
    background-image: linear-gradient(to bottom, #000000, #00000000);
}