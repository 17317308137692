@import "./../consts/LAYOUT.scss";

.container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    z-index: 1000;
}

.image {
    width: 140px;
    height: 140px;
}