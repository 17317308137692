.container {
    width: 100vw;
    position: fixed;
    bottom: 0;
    z-index: 2;
}

.navbarBackground {
    height: 100px;
    width: 100vw;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.shadow {
    height: 60px;
    margin-top: -60px;
    width: 100vw;
    background-image: linear-gradient(to top, #000000, #00000000);
}

.text {
    color: white;
    font-size: 14px;
    text-align: center;
    text-decoration: underline;
    padding: 20px;
}