.container {
    color: white;
    padding: 150px;
    font-size: 16px;
}

.largeTitle {
    font-weight: 800;
    font-size: 28px;
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
}

.title {
    font-weight: 800;
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 5px;
}

.linksContainer {
    margin-top: 20px;
    margin-bottom: 20px;
}

.link {
    color: white;
    text-decoration: underline;
}

.listItemContainer {
    margin-top: 20px;
    margin-bottom: 20px;
}

.email {
    text-decoration: underline;
}