/* COLORS */
$primary: #1469A1;
$primary30: #1469A14D;
$primary-dark: #101B29;
$accent: #FEC83B;
$brandYellow: #FDD100;
$grey: #6E6E73;
$green: #377E24;
$light-grey: #ebebeb;
$placeholder: #9DA3AE;
$white: #fff;
$black: #000;
$warning: #F23030;
$warningDark: #7e3f3f;
$darkGrey: #232323;
$darkishGrey: #2D2D2D;
$lighterGrey: #4D4D4D;

/* FONTS */
$family-light: Inter-Light;
$family-light-italic: Inter-Light;
$family-regular: Inter-Regular;
$family-regular-italic: Inter-Regular;
$family-medium: Inter-Medium;
$family-medium-italic: Inter-Medium;
$family-bold: Inter-Bold;
$family-bold-italic: Inter-Bold;

/* BORDER RADIUS */
$radius-xxsmall: 2px;
$radius-xsmall: 4px;
$radius-small: 8px;
$radius-medium: 12px;
$radius-large: 16px;
$radius-xlarge: 18px;
$radius-xxlarge: 22px;

/* BORDER WIDTHS */
$border-xxsmall: 0.5px;
$border-xsmall: 1px;
$border-small: 1.5px;
$border-medium: 2px;
$border-large: 2.5px;
$border-xlarge: 3px;
$border-xxlarge: 3.5px;

/* SPACING */
$space-xxsmall: 2px;
$space-xsmall: 4px;
$space-small: 8px;
$space-medium: 12px;
$space-large: 16px;
$space-xlarge: 18px;
$space-xxlarge: 22px;

/*  */
$font-xxsmall: 10px;
$font-xsmall: 12px;
$font-small: 14px;
$font-medium: 16px;
$font-large: 18px;
$font-xlarge: 24px;
$font-xxlarge: 28px;

/* MARGIN */
$wrapper-margin: 16px;